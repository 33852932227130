<template>
  <v-container fluid ref="content">
    <v-list-item three-line class="mb-0">
      <v-list-item-content>
        <div
          class="ma-1 d-flex flex-row justify-space-between align-center align-content-center"
        >
          <p class="display-1 text--primary">
            {{ $t('reports.titles.biorganic-report') }}
          </p>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-card class="mx-6 my-0" elevation="0" outlined>
      <v-card-text>
        <v-row class="px-lg-2 px-md-3 d-flex justify-space-between">
          <v-col cols="12" class="mr-0 pr-0">
            <v-list-item-title
              class="d-flex justify-space-between align-content-center align-center"
            >
              <div class="d-flex justify-start align-center text-filter-title">
                {{ $t('reports.titles.general-filters') }}
              </div>
            </v-list-item-title>
            <span class="pt-4">
              <v-row>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="d-flex justify-space-between align-content-center align-center"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-map-marker-radius</v-icon>
                      </v-list-item-icon>
                      {{ $t('locations.fields.name') }}<strong class="red--text">*</strong>
                    </div>
                  </v-list-item-title>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    outlined
                    dense
                    ref="locationsListFilters"
                    clearable
                    item-color="primary"
                    multiple
                    item-text="name"
                    item-value="locationId"
                    :items="locations"
                    v-model="primaryLocation"
                    :loading="loadings.origin"
                    small-chips
                    color="primary"
                    :rules="requireRules"
                    @click:clear="clearLocations()"
                    @change="fetchStorages(null, primaryLocation)"
                    @update:search-input="fetchLocations($event)"
                    :disabled="!(locationsList && locationsList.length > 0)"
                    @mouseup="
                      locationsList.length === primaryLocation.length
                        ? (checkboxState.primary = true)
                        : (checkboxState.primary = false)
                    "
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.name | truncate(20) }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ primaryLocation.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.primary"
                        color="primary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          primaryLocation = selectAll(
                            'primary',
                            primaryLocation,
                            getByProperty(
                              $refs.locationsListFilters.filteredItems,
                              'locationId'
                            ),
                            checkboxState.primary,
                            'locationId'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="primary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width:285px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="primary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="item.name"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="d-flex justify-space-between align-content-center align-center"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-map-marker-radius</v-icon>
                      </v-list-item-icon>
                      {{ $t('storage.fields.name') }}<strong class="red--text">*</strong>
                    </div>
                  </v-list-item-title>
                  <v-autocomplete
                    :no-data-text="$t('general.fields.noDataAvailable')"
                    small
                    outlined
                    dense
                    ref="secondaryOriginStorageByLocationsFiltered"
                    :disabled="!(primaryLocation && primaryLocation.length > 0)"
                    multiple
                    clearable
                    item-color="primary"
                    item-text="control_label"
                    item-value="storageDeviceId"
                    return-object
                    :loading="loadings.destination"
                    :items="storages"
                    v-model="secondaryLocations"
                    small-chips
                    @click:clear="fetchStorages(null, primaryLocation)"
                    @update:search-input="fetchStorages($event, primaryLocation)"
                    color="primary"
                    class="required"
                    :rules="requireRules"
                    @mouseup="
                      getOriginStorageByLocationsFiltered.length ===
                      secondaryLocations.length
                        ? (checkboxState.secondary = true)
                        : (checkboxState.secondary = false)
                    "
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.control_label | truncate(20) }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ secondaryLocations.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width:285px">
                        <v-checkbox
                          :input-value="attrs.inputValue"
                          color="primary"
                          class="pt-2 pb-2 mt-0"
                          :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                              :classes="'mb-0'"
                              :text="item.control_label"
                              :max-chars="80"
                              :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                        v-model="checkboxState.secondary"
                        color="primary"
                        class="pt-2 pb-2 mt-0 pl-4"
                        :hide-details="true"
                        @change="
                          secondaryLocations = selectAll(
                            'secondary',
                            secondaryLocations,
                            $refs.secondaryOriginStorageByLocationsFiltered
                              .filteredItems,
                            checkboxState.secondary,
                            'storageDeviceId'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="primary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                    class="d-flex justify-space-between align-content-center align-center"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-calendar</v-icon>
                      </v-list-item-icon>
                      {{ $t('records.fields.date') }}
                    </div>
                  </v-list-item-title>
                  <v-menu
                    v-model="menu"
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        onkeypress="return (event.charCode != 34)"
                        v-on:keyup.86="replace"
                        v-on:keyup.ctrl.86="replace"
                        small
                        outlined
                        dense
                        v-model="date"
                        readonly
                        v-on="on"
                        clearable
                        color="primary"
                        append-icon="mdi-calendar"
                        @click:clear="clearDate('recordDateRef')"
                        @click:append="() => (menu = !menu)"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      color="primary"
                      v-model="datetime"
                      range
                      @change="dateRangeText()"
                      ref="dateRef"
                      :selected-items-text="
                        '{0} ' + $t('records.filters.selected_items_text')
                      "
                    >
                      <v-spacer></v-spacer>
                      <v-btn small text color="gray" @click="menu = false">
                        {{ $t('general.buttons.cancel') }}
                      </v-btn>
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="$refs.menu.save(datetime)"
                      >
                        {{ $t('general.buttons.confirm') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4" md="4" sm="12" cols="12" class="mx-auto">
                  <v-list-item-title
                      class="d-flex justify-space-between align-content-center align-center"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-file</v-icon>
                      </v-list-item-icon>
                      {{ $t('menu.documents') }}
                    </div>
                  </v-list-item-title>
                  <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      small
                      outlined
                      dense
                      ref="documentTypesFilters"
                      :label="$t('documents.fields.type')"
                      multiple
                      clearable
                      item-color="primary"
                      item-value="id"
                      item-text="document_type_name"
                      :items="documentTypes"
                      @change="documentDescriptionFiltered(document_type_ids)"
                      @click:clear="clearDocumentType()"
                      v-model="document_type_ids"
                      small-chips
                      color="primary"
                      @mouseup="
                      documentTypes.length == document_type_ids.length
                        ? (checkboxState.document_type = true)
                        : (checkboxState.document_type = false)
                    "
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{
                          item.document_type_name | truncate(20)
                        }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                      >(+{{ document_type_ids.length - 1 }}
                        {{ $t('general.titles.others') }})
                      </span>
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width:285px">
                        <v-checkbox
                            :input-value="attrs.inputValue"
                            color="primary"
                            class="pt-2 pb-2 mt-0"
                            :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                                :classes="'mb-0'"
                                :text="item.document_type_name"
                                :max-chars="80"
                                :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                          v-model="checkboxState.document_type"
                          color="primary"
                          class="pt-2 pb-2 mt-0 pl-4"
                          :hide-details="true"
                          @change="
                          document_type_ids = selectAll(
                            'document_type',
                            document_type_ids,
                            getDescriptionsType(
                              $refs.documentTypesFilters.filteredItems,
                              checkboxState.document_type,
                              'id'
                            )
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="primary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2" />
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      small
                      outlined
                      dense
                      ref="documentDescriptionFilters"
                      :label="$t('documents.fields.description')"
                      :loading="loadingDescription"
                      :disabled="!(document_type_ids && document_type_ids.length > 0)"
                      multiple
                      clearable
                      item-color="primary"
                      item-value="id"
                      item-text="document_description_name"
                      return-object
                      :items="documentDescriptions"
                      v-model="document_description_ids"
                      small-chips
                      color="primary"
                      @mouseup="
                      documentDescriptions.length ==
                      document_description_ids.length
                        ? (checkboxState.document_description = true)
                        : (checkboxState.document_description = false)
                    "
                      @click:clear="checkboxState.document_description = false"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{
                          translate(item.language_key) | truncate(20)
                        }}</span>
                      </v-chip>
                      <span
                          v-if="index === 1"
                          class="grey--text caption"
                      >(+{{ document_description_ids.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width:285px">
                        <v-checkbox
                            :input-value="attrs.inputValue"
                            color="primary"
                            class="pt-2 pb-2 mt-0"
                            :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                                :classes="'mb-0'"
                                :text="translate(item.language_key)"
                                :max-chars="80"
                                :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                          v-model="checkboxState.document_description"
                          color="primary"
                          class="pt-2 pb-2 mt-0 pl-4"
                          :hide-details="true"
                          @change="
                          document_description_ids = selectAll(
                            'document_description',
                            document_description_ids,
                            $refs.documentDescriptionFilters.filteredItems,
                            checkboxState.document_description,
                            'id'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="primary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                      class="d-flex justify-space-between align-content-center align-center"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-barley</v-icon>
                      </v-list-item-icon>
                      {{ $t('menu.season') }}
                    </div>
                  </v-list-item-title>
                  <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      small
                      outlined
                      dense
                      ref="seasonsFilters"
                      multiple
                      clearable
                      item-color="primary"
                      item-text="season_name"
                      return-object
                      :items="seasons"
                      v-model="seasonsSelected"
                      small-chips
                      color="primary"
                      @mouseup="
                      seasons.length ===
                      seasonsSelected.length
                        ? (checkboxState.seasons = true)
                        : (checkboxState.seasons = false)
                    "
                      @click:clear="checkboxState.seasons = false"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.season_name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                      >(+{{ seasonsSelected.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width:360px">
                        <v-checkbox
                            :input-value="attrs.inputValue"
                            color="primary"
                            class="pt-2 pb-2 mt-0"
                            :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                                :classes="'mb-0'"
                                :text="item.season_name"
                                :max-chars="80"
                                :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                          v-model="checkboxState.seasons"
                          color="primary"
                          class="pt-2 pb-2 mt-0 pl-4"
                          :hide-details="true"
                          @change="
                          seasonsSelected = selectAll(
                            'seasons',
                            seasonsSelected,
                            $refs.seasonsFilters.filteredItems,
                            checkboxState.seasons,
                            'id'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="primary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <v-list-item-title
                      class="d-flex justify-space-between align-content-center align-center"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-package-variant-closed</v-icon>
                      </v-list-item-icon>
                      {{ $t('records.filters.product') }}
                    </div>
                  </v-list-item-title>
                  <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      small
                      outlined
                      dense
                      ref="productFilters"
                      multiple
                      clearable
                      item-color="primary"
                      item-text="product_name"
                      return-object
                      :items="products"
                      v-model="productsSelected"
                      small-chips
                      color="primary"
                      @mouseup="
                      products.length == productsSelected.length
                        ? (checkboxState.products = true)
                        : (checkboxState.products = false)
                    "
                      @click:clear="checkboxState.products = false"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small v-if="index === 0">
                        <span>{{ item.product_name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                      >(+{{ productsSelected.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                      >
                    </template>
                    <template v-slot:item="{ item, attrs }">
                      <div style="max-width:360px">
                        <v-checkbox
                            :input-value="attrs.inputValue"
                            color="primary"
                            class="pt-2 pb-2 mt-0"
                            :hide-details="true"
                        >
                          <template v-slot:label>
                            <read-more
                                :classes="'mb-0'"
                                :text="item.product_name"
                                :max-chars="80"
                                :font-size="14"
                            />
                          </template>
                        </v-checkbox>
                      </div>
                    </template>
                    <template v-slot:prepend-item>
                      <v-checkbox
                          v-model="checkboxState.products"
                          color="primary"
                          class="pt-2 pb-2 mt-0 pl-4"
                          :hide-details="true"
                          @change="
                          productsSelected = selectAll(
                            'products',
                            productsSelected,
                            $refs.productFilters.filteredItems,
                            checkboxState.products,
                            'id'
                          )
                        "
                      >
                        <template v-slot:label>
                          <v-btn small color="primary" x-small text>
                            {{ $t('general.titles.selectAll') }}
                          </v-btn>
                        </template>
                      </v-checkbox>
                      <v-divider class="mb-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :cols="12"
            class="px-md-3 d-flex justify-end align-end align-content-end"
          >
            <v-btn
              small
              tile
              :elevation="0"
              color="gray"
              style="border-radius: 1.5em;"
              @click="searchData()"
              :loading="loadingReport"
              class="ma-1"
              :disabled="
                !(
                  primaryLocation &&
                  secondaryLocations &&
                  secondaryLocations.length > 0
                )
              "
            >
              <v-icon small left>mdi-magnify</v-icon>
              {{ $t('reports.buttons.search') }}
              <template v-slot:loader>
                <v-progress-circular
                    style="position: absolute;
                           height: 15px;"
                    color="secondary accent-4"
                    indeterminate
                    rounded
                />
              </template>
            </v-btn>
            <v-btn
              small
              tile
              :elevation="0"
              color="gray"
              style="border-radius: 1.5em;"
              @click="exportRecords()"
              :loading="exportLoading"
              :disabled="
                !(biorganic.length > 0) || loadingReport || exportLoading
              "
              class="ma-1"
            >
              <v-icon small color="neuter" left>
                mdi-download-multiple
              </v-icon>
              <span>
                {{ $t("general.buttons.export") }}</span>
              <template v-slot:loader>
                <v-progress-circular
                    style="position: absolute;
                                                   margin-left: -3px;
                                                   height: 15px;"
                    color="secondary accent-4"
                    indeterminate
                    rounded
                />
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="ma-3 mt-10">
      <v-col cols="12" class="ma-0 pa-0">
        <v-data-table
          :headers="headers.biorganic"
          :items="biorganic"
          :loading="loading.biorganic"
          v-model="selected.biorganic"
          :show-select="showCheckBoxBiorganic"
          checkbox-color="primary"
          :no-data-text="$t('general.ui.not_data_found')"
          :loading-text="$t('general.notification.loadData')"
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
        >
          <template v-slot:item.plantio="{ item }">
            <span v-if="item.plantio">
              <v-chip small
                      class="ma-1"
                      v-if="
                        !showMorePlantio.status
                      "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ item.plantio[0].column_value | truncate(20) }}</span>
                  </template>
                  <span style="font-size: 12px; color: #f5f5f5">
                    {{ item.plantio[0] }}
                  </span>
                </v-tooltip>
              </v-chip>
              <v-chip
                  v-for="(plantio,i) in item.plantio"
                  small
                  class="ma-1"
                  v-if="showMorePlantio.status"
                  :key="i"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ plantio.column_value | truncate(20) }}</span>
                        </template>
                        <span style="font-size: 12px; color: #f5f5f5">
                      {{ plantio.column_value }}
                    </span>
                </v-tooltip>
              </v-chip>
              <v-chip
                  x-small
                  class="ma-1"
                  v-if="item.plantio.length > 1"
                  @click="expandPlantioItems()"
              >
                ({{
                !!showMorePlantio.status
                ? "-"
                : "+"
                }}
                {{ item.plantio.length - 1 }}
                {{ $t("general.titles.others") }})
              </v-chip>
            </span>
            <span v-else>
              <v-chip small disabled>
                N/A
              </v-chip>
            </span>
          </template>
          <template v-slot:item.harvestDate="{ item }">
            <span v-if="item.harvestDate">
              <v-chip small
                      class="ma-1"
                      v-if="
                      !showMoreHarvest.status
                    "
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.harvestDate[0].column_value | truncate(20) }}</span>
                </template>
                <span style="font-size: 12px; color: #f5f5f5">
                  {{ item.harvestDate[0] }}
                </span>
              </v-tooltip>
            </v-chip>
              <v-chip
                  v-for="(harvestDate,i) in item.harvestDate"
                  small
                  :key="i"
                  class="ma-1"
                  v-if="showMoreHarvest.status"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ harvestDate.column_value | truncate(20) }}</span>
                        </template>
                        <span style="font-size: 12px; color: #f5f5f5">
                      {{ harvestDate.column_value }}
                    </span>
                </v-tooltip>
              </v-chip>
              <v-chip
                  x-small
                  class="ma-1"
                  v-if="item.harvestDate.length > 1"
                  @click="expandHarvestItems()"
              >
                ({{
                !!showMoreHarvest.status
                ? "-"
                : "+"
                }}
                {{ item.harvestDate.length - 1 }}
                {{ $t("general.titles.others") }})
              </v-chip>
            </span>
            <span v-else>
              <v-chip small disabled>
                N/A
              </v-chip>
            </span>

          </template>
          <template v-slot:item.record_date_time="{ item }">
            <span>{{ getDateFormat(item.record_date_time) }}</span>
          </template>
          <template v-slot:item.origin="{ item }">
            <v-chip class="ma-1" small>{{
              item.origin
            }}</v-chip>
          </template>
          <template v-slot:item.destination="{ item }">
            <v-chip class="ma-1" small>{{
              item.destination
            }}</v-chip>
          </template>
          <template v-slot:body.append="{ items }">
            <tr v-if="biorganic && biorganic.length > 0">
              <th class="title">Total:</th>
              <th colspan="4"/>
              <th class="title">{{ sumField('area') }}</th>
              <th/>
              <th class="title">{{ sumField('quantity') }}</th>
              <th/>
              <th class="title">{{ sumField('qtde') }}</th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Export PDF -->
    <v-row justify="center">
      <v-dialog v-model="showModalExport" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{
            $t('reports.titles.exportConfigPDF')
          }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :no-data-text="$t('general.fields.noDataAvailable')"
                  color="primary"
                  :label="$t('reports.titles.orientation')"
                  item-color="primary"
                  :items="pageEnum"
                  item-value="type"
                  item-text="text"
                  v-model="orientation"
                  small-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="gray"
              @click="closeDialog()"
              :disabled="exportLoading"
            >
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              small
              text
              color="primary"
              :disabled="!orientation"
              :loading="exportLoading"
              @click="exportRecords()"
            >
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import _ from 'lodash'
  import LanguajeService from '../../services/LanguajeService'
  import ReadMore from '../../components/ReadMoreComponent'
  import moment from 'moment'
  import axios from 'axios'
  import pdf from 'vue-pdf'
  import sortList from '@/services/OrderListService'
  import { generalMixin } from '../../mixins/general.mixin'

  const STRUCTURE_TYPE = {
    MULTIPLE: [
      'PRODUCT',
      'EQUIPMENT',
      'OPERATOR',
      'INPUT',
      'MEASUREMENTUNIT',
      'SEASON',
      'LIST',
    ],
    SIMPLE: [
      'NUMBER',
      'TEXT',
      'LOTE',
      'IMO',
      'LACRE',
      'TIME',
      'DATA',
      'DATATIME',
      'PRODUCTSIMPLE',
      'EQUIPMENTSIMPLE',
      'OPERATORSIMPLE',
      'INPUTSIMPLE',
      'MEASUREMENTUNITSIMPLE',
      'SEASONSIMPLE',
      'LISTSIMPLE',
    ],
  }

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    components: { ReadMore, pdf },
    mixins: [generalMixin],
    data: () => ({
      showMorePlantio: {
        status: false,
        index: 0,
      },
      showMoreHarvest: {
        status: false,
        index: 0,
      },
      checkboxState: {
        primary: false,
        secondary: false,
        proccess: false,
        document_type: false,
        document_description: false,
        name: false,
        seasons: false,
        products: false
      },
      profile: null,
      exportLoading: false,
      showDialog: false,
      showDocumentDialog: false,
      showModalExport: false,
      loadingReport: false,
      loading: {
        biorganic: false
      },
      loadingDescription: false,
      primaryLocation: [],
      secondaryLocations: [],
      seasonsSelected: [],
      document_type_ids: [],
      document_description_ids: [],
      process_ids: [],
      template_names: [],
      productsSelected: [],
      date: '',
      datetime: [],
      menu: false,
      loadings: {
        origin: false,
        destination: false
      },
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 15,
          sortBy: 'id',
          direction: 'ASC',
        }
      },
      filters: {
        biorganic: {
          pageableDTO: null,
        }
      },
      filter: {
        biorganic: true
      },
      selected: {
        biorganic: []
      },
      showCheckBoxBiorganic: false,
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      optionsB: {},
      headers: {
        biorganic: [
          {
            text: i18n.t('locations.fields.name'),
            locale: i18n.t('records.fields.originReg'),
            value: 'origin',
            val: 'origin',
            type: 'text',
            width: 250,
            sortable: false
          },
          {
            text: i18n.t('storage.fields.name'),
            locale: i18n.t('records.fields.destinationRed'),
            value: 'destination.name',
            val: 'destination.name',
            type: 'text',
            width: 250,
            sortable: false
          },
          {
            text: i18n.t('product.fields.name'),
            value: 'columnsProcess1.product',
            val: 'columnsProcess1.product',
            type: 'text',
            width: 140,
            sortable: false
          },
          {
            text: i18n.t('records.fields.season'),
            value: 'columnsProcess1.season',
            val: 'columnsProcess1.season',
            type: 'text',
            width: 200,
            sortable: false
          },
          {
            text: 'Plantio',
            value: 'columnsProcess1.plantio.column_value',
            val: 'columnsProcess1.plantio.column_value',
            type: 'date',
            width: 120,
            sortable: false
          },
          {
            text: 'Área plantada (Ha)',
            value: 'columnsProcess1.area.column_value',
            val: 'columnsProcess1.area.column_value',
            type: 'text',
            width: 200,
            sortable: false
          },
          {
            text: 'Provável data para a colheita',
            value: 'columnsProcess2.date.column_value',
            val: 'columnsProcess2.date.column_value',
            type: 'text',
            width: 300,
            sortable: false
          },
          {
            text: 'Qtde estimada (ton/ha)',
            value: 'columnsProcess2.quantity.column_value',
            val: 'columnsProcess2.quantity.column_value',
            type: 'text',
            width: 250,
            sortable: false
          },
          {
            text: 'Data da colheita',
            value: 'columnsProcess3.date.column_value',
            val: 'columnsProcess3.date.column_value',
            type: 'text',
            width: 220,
            sortable: false
          },
          {
            text: 'Qtde Colhida (ton)',
            value: 'columnsProcess3.quantity.column_value',
            val: 'columnsProcess3.quantity.column_value',
            type: 'text',
            width: 190,
            sortable: false
          },
          {
            text: 'Certificação',
            value: 'certification.value',
            val: 'certification.value',
            type: 'text',
            width: 150,
            sortable: false
          },
          {
            text: 'Em conversão',
            value: 'conversion.value',
            val: 'conversion.value',
            type: 'text',
            width: 160,
            sortable: false
          },
        ]
      },
      currentPage: 1,
      pageCount: 0,
      orientation: null,
    }),

    // watch: {
    //   optionsB: {
    //     handler() {
    //       if (
    //         this.primaryLocation &&
    //         this.primaryLocation.length > 0 &&
    //         this.secondaryLocations &&
    //         this.secondaryLocations.length > 0
    //       ) {
    //         this.loading.biorganic = true
    //
    //         const CancelToken = axios.CancelToken
    //         let source = CancelToken.source()
    //
    //         this.fetchBiorganicData([this.filters.biorganic, source, this.$toast]).then(
    //             () => {
    //               this.loading.biorganic = false
    //             }
    //         )
    //       }
    //     },
    //     deep: true,
    //   },
    // },

    computed: {
      documentTypes() {
        const data = this.$store.getters['document_type/documentTypes']
        return _.filter(data, (o) => {
          return [194, 305].includes(o.id)
        })
      },
      documentDescriptions() {
        const data = this.$store.getters['document_description/documentDescriptions']
        return _.filter(data, (o) => {
          return [2130, 2131, 2132, 2133, 1547, 1548, 1549, 1550].includes(o.id)
        })
      },
      ...mapGetters({
        biorganic: 'reports/biorganic',
        biorganicIds: 'reports/biorganicIds',
        totalBiorganicFromServer: 'reports/totalBiorganicFromServer',
        process: 'proccess/proccess',
        seasons: "season/seasons",
        products: 'product/products',
        locationsList: 'authority/authoritiesLocations',
        allStoragesByUser: 'authority/authoritiesStoragesDevices',
        generalLoading: 'general/loading',
        pageEnum: 'reports/pageEnum',
        requireRules: "general/requireRules",
        storages: 'storage/storagesList',
        locations: 'locations/locationsList',
      }),
      ifBiorganicDataFound() {
        return this.totalBiorganicFromServer || this.totalDocumentsFromServer
      },
      isImageOrPdf() {
        return ['jpg', 'png', 'jpeg', 'pdf'].includes(
          this.documentSelected.file_extension
        )
      },
      getOriginStorageByLocationsFiltered() {
        return sortList.orderListByUppercase(
          this.getStorageByLocationsFilteredAction(this.primaryLocation, 'id'),
          ['name']
        )
      },
      // Exist structures
      existStructures() {
        return (
          this.recordSelected.recordStructureValuesOrganized.structures &&
          this.recordSelected.recordStructureValuesOrganized.structures.length >
            0
        )
      },
      // Exist groups
      existGroups() {
        return (
          this.recordSelected.recordStructureValuesOrganized.groups &&
          this.recordSelected.recordStructureValuesOrganized.groups.length > 0
        )
      },
      // Get structures
      getStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures
          ? this.recordSelected.recordStructureValuesOrganized.structures
          : []
      },
      // Get groups
      getGroups() {
        if (this.flagGroups) this.flagGroups = false
        return this.recordSelected.recordStructureValuesOrganized.groups
          ? this.recordSelected.recordStructureValuesOrganized.groups
          : []
      }
    },
    created() {
      this.profile = JSON.parse(localStorage.getItem('profile'))
    },
    methods: {
      ...mapActions({
        fetchAllTemplates: 'survey/fetchAllTemplates',
        fetchListDocumentDescriptionsByType:
          'document_description/fetchListDocumentDescriptionsByType',
        fetchBiorganicData: 'reports/fetchBiorganicDataForGeneralReport',
        getBiorganicReportCSV: 'reports/getBiorganicReportCSV',
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchStorageLocations: "storage/fetchFilteredStorages"
      }),
      async clearLocations() {
        this.$set(this, 'secondaryLocations', []);
        this.$store.commit('storage/RESTORE_STORAGE_LIST')
        this.$set(this, 'primaryLocation', []);

        await this.fetchLocations(null)

        this.checkboxState.primary = false
        this.checkboxState.secondary = true
      },
      async fetchLocations(e) {
        let byIds = false;

        if (!e) {
          byIds = true;
        }

        this.$set(this.loadings, 'origin', true);

        const filters = {
          stringFilter: e || "",
          ids: byIds ? this.primaryLocation : null,
          isActive: true
        }

        await this.fetchPrimaryLocations([filters, this.$toast]).finally(() => {
          this.$set(this.loadings, 'origin', false);
        })
      },
      async fetchStorages(e, pl) {
          this.$set(this.loadings, 'destination', true);

          await this.fetchStorageLocations([{
            primaryLocationIds: this.primaryLocation,
            stringFilter: e || "",
            isActive: true
          }, this.$toast]).finally(() => {
            this.$set(this.loadings, 'destination', false);
          })
      },
      expandPlantioItems() {
        this.showMorePlantio.status = !this.showMorePlantio.status
      },
      expandHarvestItems() {
        this.showMoreHarvest.status = !this.showMoreHarvest.status
      },
      sumField(key) {
        if(this.biorganic && this.biorganic.length > 0)
          return this.biorganic.reduce((a, b) => a + ((b[key]) || 0), 0).toFixed(2)
      },
      capitalizeFirstLetter(string) {
        string = string.toLowerCase()
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      selectAll(type, length, value, checkbox, idField) {
        if( checkbox === false ){
          this.$nextTick(function(){
            switch (type) {
              case 'primary':
                this.checkboxState.primary = false
                break
              case 'secondary':
                this.checkboxState.secondary = false
                break
              case 'proccess':
                this.checkboxState.proccess = false
                break
              case 'document_type':
                this.checkboxState.document_type = false
                break
              case 'document_description':
                this.checkboxState.document_description = false
                break
              case 'name':
                this.checkboxState.name = false
                break
              case 'seasons':
                this.checkboxState.seasons = false
                break
              case 'products':
                this.checkboxState.products = false
                break
            }
          })
          return [];
        }

        console.log(idField)

        let aux = [];
        if( length != null ) {
          if (typeof length[0] === 'object') {
            _.forEach(length, (object) => {
              if (!_.find(value, (o) => {
                return o[idField] === object[idField]
              }))
                value.push(object)
            })
          } else {
            _.forEach(length, (text) => {
              if (!value.includes(text))
                value.push(text)
            })
          }

          aux = value;
        }else{
          aux = value;
        }
        return aux;
      },

      async searchData() {
        this.loadingReport = true

        this.selected = {
          biorganic: []
        }

        this.optionsB.page = 1

        let data = {
          primaryLocation: _.cloneDeep(this.primaryLocation),
          secondaryLocations: this.getByProperty(
            _.cloneDeep(this.secondaryLocations),
            'storageDeviceId'
          ),
          documentType:
            this.document_type_ids.length > 0
              ? _.cloneDeep(this.document_type_ids)
              : null,
          documentDescriptions:
            this.document_description_ids.length > 0
              ? this.getByProperty(
                  _.cloneDeep(this.document_description_ids),
                  'id'
                )
              : null,
          products:
            this.productsSelected.length > 0
              ? this.getByProperty(_.cloneDeep(this.productsSelected), 'product_name')
              : null,
          seasons:
              this.seasonsSelected.length > 0
                  ? this.getByProperty(_.cloneDeep(this.seasonsSelected), 'season_name')
                  : null,
          date:
            this.datetime.length > 0
              ? [
                  moment(this.datetime[0]).format('YYYY-MM-DD') + 'T00:00:01',
                  moment(this.datetime[1]).format('YYYY-MM-DD') + 'T23:59:59',
                ]
              : [],
        }

        this.filters.biorganic = {
          company_id: this.profile.company_id,
          language: LanguajeService.getLenguajeName(),
          commonFilters: {
            sdi_ids: data.secondaryLocations,
            date: data.date,
          },
          recordFilter: {
            // pageableDTO: this.getPaginationByModule(
            //     this.optionsB,
            //     this.headers.biorganic
            // ),
            pageableDTO: {
              page: 0,
              size: 10,
              sortBy: 'id',
              direction: 'desc',
            },
            process_ids: [1050, 1057, 1052],
            products_ids: data.products,
            seasons_ids: data.seasons,
          },
          documentFilter: {
            document_type_ids: data.documentType,
            document_description_ids: data.documentDescriptions
          }
        }

        const CancelToken = axios.CancelToken
        let source = CancelToken.source()

        let promises = []

        if (this.filter.biorganic) {
          this.loading.biorganic = true

          promises.push(
            this.fetchBiorganicData([
              this.filters.biorganic,
              source,
              this.$toast,
            ]).then(() => {
              this.loading.biorganic = false
            })
          )
        }

        await Promise.all(promises).finally(() => {
          this.loadingReport = false
        })
      },
      getPaginationByModule(option, headers) {
        const direction =
          option.sortDesc.length === 0 || option.sortDesc[0] ? 'desc' : 'asc'
        const sort =
          option.sortBy.length === 0
            ? 'id'
            : _.filter(headers, { value: option.sortBy[0] })[0].val
        return {
          page: option.page - 1,
          size: option.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }
      },
      // Get data more details dialog
      /*async showBiorganic(item) {
        this.loading.biorganic = true
        await this.findBiorganicDataById([item.id, this.$toast]).finally(() => {
          this.loading.biorganic = false
          this.showBiorganicDialog = true
        })
      },*/
      closeDialog() {
        this.orientation = null
        this.showModalExport = false
        this.exportLoading = false
      },
      // closeDetails(type) {
      //   switch (type) {
      //     case 'biorganic':
      //       this.$store.commit('records/SET_BIORGANIC_ORIGINAL_STATE')
      //       this.showBiorganicDialog = false
      //       return
      //   }
      // },
      checkBoxShow(module) {
        switch (module) {
          case 'biorganic':
            this.showCheckBoxBiorganic = !this.showCheckBoxBiorganic
            if (!this.showCheckBoxBiorganic) this.selected.biorganic = []
            return
        }
      },
      transformAnswers(question, answers) {
        if (STRUCTURE_TYPE.SIMPLE.includes(question.questionStructureType)) {
          let dateFormatted = (
            LanguajeService.getKey3(answers[0].answer) + '.000Z'
          ).slice(0, 19)
          return question.questionStructureType === 'DATA'
            ? moment(dateFormatted).format('DD / MM / YYYY')
            : question.questionStructureType === 'TIME'
            ? moment(dateFormatted).format('HH:mm')
            : question.questionStructureType === 'DATATIME'
            ? moment(dateFormatted).format('DD / MM / YYYY HH:mm')
            : LanguajeService.getKey3(answers[0].answer)
        }
        if (STRUCTURE_TYPE.MULTIPLE.includes(question.questionStructureType)) {
          let text = []
          _.forEach(answers, answer => {
            text.push(LanguajeService.getKey3(answer.answer))
          })
          return _.sortBy(text).join(', ')
        }
      },
      sectionAverage(section) {
        return section.questionSectionPercentPunctuation
      },
      dateRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        let start = ''
        let end = ''
        let both = []
        if (this.datetime.length > 1) {
          if (this.datetime[0]) {
            ;[year, month, day] = this.datetime[0].split('-')
            start = moment(this.datetime[0]).format('YYYY-MM-DD') + 'T00:00:01'
          }
          if (this.datetime[1]) {
            ;[year2, month2, day2] = this.datetime[1].split('-')
            end = moment(this.datetime[1]).format('YYYY-MM-DD') + 'T23:59:59'
            both = [start, end]
          }
          this.date = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },
      getDateFormat(dateString) {
        return moment(new Date(dateString)).format('DD/MM/YYYY HH:mm')
      },
      clearDate() {
        this.datetime = []
      },
      dialogClose() {
        this.orientation = null
        this.showDialog = false
        this.exportLoading = false
      },
      exportDialog() {
        this.showDialog = true
      },
      async exportRecords() {
        this.exportLoading = true

        // const biorganicFilter = _.pick(this.filters.biorganic, ['pageableDTO'])

        /*const filters = {
          company_id: this.profile.company_id,
          language: LanguajeService.getLenguajeName(),
          biorganicFilter:
            this.selected.biorganic && this.selected.biorganic.length > 0
              ? {
                  biorganic_ids: this.getByProperty(
                    this.selected.biorganic,
                    'id'
                  ),
                }
              : {
                  ...biorganicFilter,
                },

          commonFilters: {
            sdi_ids: this.getByProperty(
              _.cloneDeep(this.secondaryLocations),
              'id'
            ),
            date:
              this.datetime.length > 0
                ? [
                    moment(this.datetime[0]).format('YYYY-MM-DD') + 'T00:00:01',
                    moment(this.datetime[1]).format('YYYY-MM-DD') + 'T23:59:59',
                  ]
                : [],
            biorganic_export: this.filter.biorganic
          },
        }*/

        await this.getBiorganicReportCSV([this.filters.biorganic, this.$toast]).finally(() => {
          this.exportLoading = false
        })
      },

      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },

      updateSecondaryLocations(event) {
        if (event.length > 0) {
          _.forEach(this.secondaryLocations, (storage, index) => {
            if (storage && !event.includes(storage.primaryLocationId)) {
              this.secondaryLocations.splice(index, 1)
            }
          })
        } else {
          this.secondaryLocations = []
        }
      },

      clearDocumentType() {
        this.document_description_ids = []
        this.checkboxState.document_type = false
      },

      getStorageByLocationsFilteredAction(data) {
        return data && data.length > 0
          ? _.filter(this.allStoragesByUser, storage => {
              if (data.includes(storage.primaryLocationId)) {
                return storage
              }
            })
          : []
      },
      getDescriptionsType(data) {
        this.documentDescriptionFiltered(this.getByProperty(data, 'id'))

        return this.getByProperty(data, 'id')
      },
      async documentDescriptionFiltered(type) {
        this.loadingDescription = true
        if (!(type && type.length > 0)) {
          this.document_description_ids = []
          this.loadingDescription = false
          return
        }

        await this.fetchListDocumentDescriptionsByType([
          this.profile.company_id,
          type,
          this.$toast,
        ]).finally(() => {
          this.loadingDescription = false
        })
      },

      zoomPlus() {
        if (this.width + 50 >= 1000) {
          return
        }
        return (this.width += 50)
      },

      zoomLess() {
        if (this.width - 50 <= 500) {
          return
        }
        return (this.width -= 50)
      },
      nextPage() {
        if (this.pageCount >= this.currentPage + 1) this.currentPage++
      },

      backPage() {
        if (this.currentPage > 1) this.currentPage--
      },

      getByProperty(array, property) {
        return _.map(array, property)
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
    },

    destroyed() {
      this.$store.commit('reports/SET_BIORGANIC_ORIGINAL_STATE')
      this.$store.commit('reports/SET_DOCUMENTS_ORIGINAL_STATE')
    }
  }
</script>

<style scoped lang="scss">
  .text-filter-title {
    background-color: color-mix(in srgb, var(--v-primary-base) 15%, transparent);
    color: var(--v-primary-base);

    padding: 10px;
    border-radius: 0.1em;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
  }
</style>
